import moment from 'moment';
import store from '@/store';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import getPassengerAge from './getPassengerAge';

const EMAIL_REGEX = {
  gfa: /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24}$/,
  conectagfa: /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24}$/,
  default:
    /^([a-zA-Z0-9])+(((_)+|-|\.)?([a-zA-Z0-9-])+((_))?)*@([\w-]+(-|\.))*[a-zA-Z0-9]+\.[a-zA-Z]{2,}$/,
};

const isEmpty = (value) => !value || !value.toString().trim();
const join = (rules) => (value, data) =>
  rules.map((rule) => rule(value, data)).filter((error) => !!error)[0];

export function feedbackClass(field) {
  let cls = '';

  if (field.active) {
    cls = '';
  } else if (field.touched) {
    if (field.valid) {
      cls = 'isValid';
    } else {
      cls = 'has-error';
    }
  }

  return cls;
}

export function hasFeedback(field) {
  return Boolean(field.error && field.touched);
}

export function email(value) {
  if (!isEmpty(value)) {
    const { env } = store.getState().whitelabelConfig;
    const currentRegex = EMAIL_REGEX[env?.brand] || EMAIL_REGEX.default;

    if (!currentRegex.test(value)) {
      return 'Invalid email address';
    }
  }
}

const getRepetitionCountIn = (digit, digitsArray) => {
  const repetitionCount = digitsArray.reduce((repetitionCount, digitInArray) => {
    if (digitInArray === digit) {
      return repetitionCount + 1;
    }
    return repetitionCount;
  }, 0);
  return repetitionCount;
};

export function phone(value, fields) {
  const { phoneCountry: country } = fields;
  if (isEmpty(value)) {
    return;
  }

  const digits = value.replace(/[^\d]/g, '');
  const allDigitsAreTheSameRegex = /\b(\d)\1+\b/;
  if (allDigitsAreTheSameRegex.test(digits)) {
    return 'Invalid phone number';
  }

  const digitsArray = digits.split('');
  const hasMoreThanSixRepeatedDigits = digitsArray.some(
    (digit) => getRepetitionCountIn(digit, digitsArray) > 6,
  );

  if (hasMoreThanSixRepeatedDigits) {
    return 'Invalid phone number';
  }

  if (!isValidPhoneNumber(value, country)) {
    return `Invalid phone number for ${country}`;
  }
}

export function required(value) {
  if (value === true) {
    return;
  }
  if (value === false || isEmpty(value)) {
    return 'Required';
  }
}

export function minLength(min) {
  return (value) => {
    if (!isEmpty(value) && value.length < min) {
      return `Must be at least ${min} characters`;
    }
  };
}

export function maxLength(max) {
  return (value) => {
    if (!isEmpty(value) && value.length > max) {
      return `Must be no more than ${max} characters`;
    }
  };
}

export function integer(value) {
  if (!Number.isInteger(Number(value))) {
    return 'Must be an integer';
  }
}

export function match(field) {
  return (value, data) => {
    if (data) {
      if (value !== data[field]) {
        return 'Do not match';
      }
    }
  };
}

export function minWords(count) {
  return (value) => {
    if (!isEmpty(value) && value.trim().split(' ').length < count) {
      return `Must have at least ${count} words`;
    }
  };
}

export function minValue(min) {
  return (value) => {
    if (parseInt(value, 10) > parseInt(min, 10)) {
      return `Must be at least ${min}`;
    }
  };
}

export function date(value) {
  if (!moment(value, 'YYYY-MM-DD', true).isValid()) {
    return 'Invalid date';
  }
}

export function taxpayerId(value, fields) {
  if (fields.invoice && (!value || value.length < 11)) return 'Invalid taxpayer ID';
}

export function legalName(value, fields) {
  if (fields.invoice && (!value || value === 'RUC Invalido')) return 'Legal name is required';
}

/**
 * Returns a regular expression pattern based on the document type.
 *
 * @param {string} type - The type of the document.
 * @returns {RegExp} The regular expression pattern for the specified document type.
 */
function getDocumentPattern(type) {
  switch (type) {
    case 'PPN':
    case 'PS':
      return /^(\w{7,12})$/i;
    case 'CC':
      return /^(\d{7,12})$/i;
    case 'CE':
      return /^(\d{7,12})$/i;
    case 'TI':
      return /^(\d{7,12})$/i;
    case 'PEP':
      return /^([a-zA-Z0-9]{6,})$/i;
    case 'NIT':
      return /^[1-9]\d{6,9}$/i;
    case 'DNI':
      return /^\d{8}$/i;
    default:
      return new RegExp('');
  }
}

/**
 * Validates a document ID based on the provided value and fields.
 *
 * @param {string} value - The document ID to validate.
 * @param {Object} fields - The fields containing the document type.
 * @returns {string|undefined} An error message if validation fails, otherwise undefined.
 */
export function documentId(value, fields) {
  if (isEmpty(value)) {
    return 'Document id required';
  }

  const { documentType } = fields;
  const pattern = getDocumentPattern(documentType);

  if (!pattern.test(value)) {
    if (fields.documentType === 'NIT') {
      return 'document_type_nit';
    }
    return 'Invalid format';
  }

  if (documentType === 'CPF' && !cpf.isValid(value)) {
    return 'invalid cpf';
  }
  if (documentType === 'CNPJ' && !cnpj.isValid(value)) {
    return 'invalid cnpj';
  }
}

/**
 * Validates a document selected based on the provided value and fields.
 *
 * @param {string} value - The document selected to validate.
 * @returns {string|undefined} An error message if validation fails, otherwise undefined.
 */
export function documentSelected(value) {
  if (isEmpty(value)) {
    return 'Document is required';
  }

  const fileSizeSegment = value.split('::')[0];
  const fileSize = fileSizeSegment.split(':')[1];
  const fileSizeInMB = fileSize / (1024 * 1024);

  const isSizeValid = fileSizeInMB <= 3;

  if (!isSizeValid) {
    return 'Document size must be maximum 3MB';
  }
}

const adultExists = (passengers) => {
  return passengers.some((passenger) => {
    const age = getPassengerAge(passenger.dateOfBirth);
    return age >= 18;
  });
};

export const checkIsMinor = (passengers) => {
  if (!adultExists(passengers)) {
    return 'An adult must be present';
  }
};

export const checkMinorTravelMode = (passengers, passenger, age) => {
  if (isEmpty(passenger.minorTravelMode)) {
    return 'Minor Passenger Mode required';
  }
  if (passenger.minorTravelMode === 'notarialPermissionTravel' && age < 12) {
    return 'You must be 12 years or older';
  }
};

export function passengers(passengers = []) {
  const { features } = store.getState().whitelabelConfig;
  const {
    IDENTIFICATION_DOCUMENT_PER_PASSENGER: checkIdentificationDocument,
    BIRTH_DATE_PER_PASSENGER: checkBirthDate,
    NATIONALITY_PER_PASSENGER: checkNationality,
    DOCUMENT_PER_PASSENGER: checkDocumentSelect,
    GENDER_PER_PASSENGER: checkGender,
    PHONE_NUMBER_PER_PASSENGER: checkPhoneNumber,
    IDENTIFICATION_SECOND_DOCUMENT_PER_PASSENGER: checkIdentificationSecondDocument,
    MINOR_RULES: minorTravelMode,
    MINOR_VALIDATION: minorValidation,
    SECOND_LAST_NAME_REQUIRED_FOR_DNI: checkSecondLastNameForDNI,
  } = features;

  const passengerErrorsArray = [];
  const passengerNames = [];

  passengers.forEach((passenger, index) => {
    const passengerError = {};
    const age = getPassengerAge(passenger.dateOfBirth);

    if (index === 0 && (isEmpty(passenger.email) || email(passenger.email))) {
      passengerError.email = 'First passenger email required';
    }

    if (passenger.email && (isEmpty(passenger.email) || email(passenger.email))) {
      passengerError.email = 'First passenger email required';
    }

    if (isEmpty(passenger.firstName)) {
      passengerError.firstName = 'First name required';
    }

    if (isEmpty(passenger.lastName)) {
      passengerError.lastName = 'Last name required';
    }

    // secondLastName is only requried for CDS with DNI
    if (
      isEmpty(passenger.secondLastName) &&
      checkSecondLastNameForDNI &&
      passenger.documentType === 'DNI'
    ) {
      passengerError.secondLastName = 'Last name required';
    }

    if (!isEmpty(passenger.firstName) && !isEmpty(passenger.lastName)) {
      const currentName = `${passenger.firstName} ${passenger.lastName} ${
        passenger.secondLastName ? passenger.secondLastName : ''
      }`
        .replace(/\s+/g, '')
        .toLowerCase();

      if (passengerNames.includes(currentName) && !features.ALLOW_DUPLICATED_PASSENGERS) {
        passengerError.firstName = 'duplicated name';
        passengerError.lastName = 'duplicated name';
        passengerError.secondLastName = 'duplicated name';
      }
    }

    if (!isEmpty(passenger.firstName))
      if (isEmpty(passenger.busCategory)) {
        passengerError.busCategory = 'Bus Category required';
      }

    if (checkIdentificationDocument) {
      if (isEmpty(passenger.documentType)) {
        passengerError.documentType = 'Document type required';
      }

      const duplicatedId =
        age < 18 && minorTravelMode
          ? false
          : passengers.some((el, indexEl) => {
              if (indexEl === index) return false;

              const isSameDocument = el.documentId && el.documentId === passenger.documentId;

              if (minorTravelMode) {
                const elAge = getPassengerAge(el.dateOfBirth);
                return isSameDocument && elAge >= 18;
              }
              return isSameDocument;
            });

      if (duplicatedId && !features.ALLOW_DUPLICATED_PASSENGERS) {
        passengerError.documentId = 'duplicated id';
      } else {
        passengerError.documentId = documentId(passenger.documentId, passenger);
      }
    }

    if (checkIdentificationSecondDocument) {
      if (isEmpty(passenger.documentTypeSecond)) {
        passengerError.documentTypeSecond = 'Document type required';
      }

      const duplicatedId = Boolean(
        passengers.find(
          (el, indexEl) =>
            el.documentIdSecond &&
            el.documentIdSecond === passenger.documentIdSecond &&
            indexEl !== index,
        ),
      );

      if (duplicatedId) {
        passengerError.documentIdSecond = 'duplicated id';
      } else {
        passengerError.documentIdSecond = documentId(passenger.documentIdSecond, passenger);
      }
    }

    if (checkBirthDate) {
      if (isEmpty(passenger.dateOfBirth)) {
        passengerError.dateOfBirth = 'Birth date required';
      } else if (age < 18) {
        if (minorTravelMode) {
          passengerError.minorTravelMode = checkMinorTravelMode(passengers, passenger, age);
        } else if (minorValidation) {
          passengerError.dateOfBirth = checkIsMinor(passengers);
        }
      } else {
        passengerError.dateOfBirth = date(passenger.dateOfBirth);
      }
    }

    if (checkNationality && isEmpty(passenger.nationality)) {
      passengerError.nationality = 'Nationality is required';
    }

    if (checkDocumentSelect) {
      passengerError.document = documentSelected(passenger.document);
    }

    if (checkGender && isEmpty(passenger.gender)) {
      passengerError.gender = 'Gender is required';
    }

    if (checkPhoneNumber && isEmpty(passenger.phone)) {
      passengerError.phone = 'Phone number is required';
    }

    passengerNames.push(
      `${passenger.firstName} ${passenger.lastName} ${
        passenger.secondLastName ? passenger.secondLastName : ''
      }`
        .replace(/\s+/g, '')
        .toLowerCase(),
    );

    passengerErrorsArray[index] = passengerError;
  });

  if (passengerErrorsArray.length) {
    return passengerErrorsArray;
  }
}

export function createValidator(rules) {
  return (data = {}) => {
    const errors = {};

    Object.keys(rules).forEach((key) => {
      if (!rules[key]) return;
      // concat enables both functions and arrays of functions
      const rule = join([].concat(rules[key]));
      const error = rule(data[key], data);

      if (error) {
        errors[key] = error;
      }
    });

    return errors;
  };
}
